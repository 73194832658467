'use strict'

angular
  .module 'home'
  .config ($stateProvider) ->
    $stateProvider
      .state 'home',
        url: '/home'
        templateUrl: 'home/views/home.tpl.html'
        controller: 'HomeCtrl'
        controllerAs: 'home'
        data:
          navigation: true
        onEnter: [
          '$log'
          '$state'
          '$timeout'
          '$rootScope'
          ($log, $state, $timeout, $rootScope) ->
            # Redirect the user to the actual home state if it's not this one
            homeState = $rootScope.branding.homeState
            if homeState != 'home'
              $log.debug "Home: Redirecting to home state '#{homeState}'"
              $timeout () -> $state.go homeState, 0
        ]
